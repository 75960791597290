import { userAxios } from '@/services'

const membershipTypeList = async (perPage) => {
    try {
        return await userAxios.get(`code-membership-type/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipTypeCreate = async (data) => {
    try {
        return await userAxios.post('code-membership-type', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipTypeUpdate = async (id, data) => {
    try {
        return await userAxios.put(`code-membership-type/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipTypeDelete = async (id) => {
    try {
        return await userAxios.delete(`code-membership-type/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipTypePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`code-membership-type/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipTypeFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`code-membership-type/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipTypeFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`code-membership-type/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    membershipTypeList,
    membershipTypeCreate,
    membershipTypeUpdate,
    membershipTypeDelete,
    membershipTypePagination,
    membershipTypeFilter,
    membershipTypeFilterPagination
}